import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FirstLoginComponent } from './first-login/first-login.component';
import { DashboardTransactionsComponent } from './dashboard/dashboard-transactions/dashboard-transactions.component';
import { MyInvoicesComponent } from './my-invoices/my-invoices.component';
import { NeedAuthGuard } from './authentication/needauthguard';
import { NeedRootGuard } from './authentication/needrootguard';
import { NeedTenancyAdminGuard } from './authentication/needtaguard';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [NeedAuthGuard] },
  { path: 'dashboard/transactions', component: DashboardTransactionsComponent, canActivate: [NeedAuthGuard] },
  { path: 'dashboard/management', loadComponent: () => import('./dashboard/management-panel/management-panel.component').then(m => m.ManagementPanelComponent), canActivate: [NeedTenancyAdminGuard], title: $localize `Management Panel` },
  { path: 'invoices', component: MyInvoicesComponent, canActivate: [NeedAuthGuard] },
  { path: 'first-login', component: FirstLoginComponent, canActivate: [NeedAuthGuard] },
  { path: 'automl', loadChildren: () => import('./auto-ml/auto-ml.module').then(m => m.AutoMLModule), canActivate: [NeedAuthGuard] },

  { path: 'login', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'user-management', loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule), canActivate: [NeedTenancyAdminGuard] },
  { path: 'vending', loadChildren: () => import('./vending/vending.module').then(m => m.VendingModule), canActivate: [NeedRootGuard] },
  { path: 'radiomics', loadChildren: () => import('./radiomics/radiomics.module').then(m => m.RadiomicsModule), canActivate: [NeedAuthGuard] },
  { path: 'cdss/tenancy-applications/:tid', loadComponent: () => import('./dss/manage-tenancy-applications/manage-tenancy-applications.component').then(m => m.ManageTenancyApplicationsComponent), canActivate: [NeedRootGuard], title: $localize `Manage CDSS Apps in Tenancy` },
  { path: 'cdss', loadChildren: () => import('./dss/dss.module').then(m => m.DssModule), canActivate: [NeedAuthGuard] },
  { path: 'sharing', loadChildren: () => import('./sharing/sharing.module').then(m => m.SharingModule) },
  { path: 'dicom-node', loadChildren: () => import('./dicom-node/dicom-node.module').then(m => m.DicomNodeModule), canActivate: [NeedTenancyAdminGuard] },
  { path: 'tenancies', loadChildren: () => import('./tenancies/tenancies.module').then(m => m.TenanciesModule), canActivate: [NeedAuthGuard] },
  { path: 'workspaces', loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule), canActivate: [NeedAuthGuard] },
  { path: 'audit-trail', loadChildren: () => import('./audit-trail/audit-trail.module').then(m => m.AuditTrailModule), canActivate: [NeedTenancyAdminGuard] },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
